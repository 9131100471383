var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.tamanhoDialogo},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v(" Detalhamento de erro de importação "),_c('v-data-table',{attrs:{"items":_vm.itensFormatados,"headers":_vm.headers,"server-items-length":_vm.totalDeRegistros,"options":_vm.paginacao,"loading":_vm.buscando},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.mensagemDeErro",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-erro"},on),[_vm._v(" "+_vm._s(item.mensagemDeErro)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.mensagemDeErro))])])]}},{key:"item.endereco",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-endereco"},on),[_vm._v(" Rua: "+_vm._s(item.endereco.rua)+", Número: "+_vm._s(item.endereco.numero)+" CEP: "+_vm._s(item.endereco.cep)+", Bairro: "+_vm._s(item.endereco.bairro)+" Complemento: "+_vm._s(item.endereco.complemento)+" Cidade: "+_vm._s(item.endereco.cidade)+" UF: "+_vm._s(item.endereco.uf)+" Código IBGE: "+_vm._s(item.endereco.codigoIBGE)+" ")])]}}],null,true)},[_c('div',[_vm._v(" Rua: "+_vm._s(item.endereco.rua)+", ")]),_c('div',[_vm._v(" Número: "+_vm._s(item.endereco.numero)+", ")]),_c('div',[_vm._v(" CEP: "+_vm._s(item.endereco.cep)+", ")]),_c('div',[_vm._v(" Bairro: "+_vm._s(item.endereco.bairro)+", ")]),_c('div',[_vm._v(" Complemento: "+_vm._s(item.endereco.complemento)+", ")]),_c('div',[_vm._v(" Cidade: "+_vm._s(item.endereco.cidade)+", ")]),_c('div',[_vm._v(" UF: "+_vm._s(item.endereco.uf)+" ")]),_c('div',[_vm._v(" Código IBGE: "+_vm._s(item.endereco.codigoIBGE)+" ")])])]}}],null,true)},[_c('v-toolbar-title',[_vm._v("Detalhamento de erro de importação")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }